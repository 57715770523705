<template>
    <!-- No template content needed as this component handles auto-logout functionality -->
</template>

<script>
export default {
    name: "AutoLogout",
    data() {
        return {
            events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
            logoutTimer: null,
            warningTimer: null,
            keepAliveTimer: null, // New timer for keep-alive
            timeout: (process.env.MIX_SESSION_LIFETIME || 120) * 60 * 1000,
            warningTime: (process.env.MIX_SESSION_WARNING_LIFETIME || 5) * 60 * 1000,
            keepAliveTime: (process.env.MIX_SESSION_WARNING_LIFETIME || 5) * 60 * 1000,
        };
    },
    mounted() {
        // console.log(process.env.MIX_SESSION_LIFETIME + " " + process.env.MIX_SESSION_WARNING_LIFETIME);
        
        this.events.forEach((event) => {
            window.addEventListener(event, this.resetTimer);
        });
        this.setTimers();

        // Listen for logout from other tabs
        window.addEventListener("storage", this.syncLogout);
    },
    beforeDestroy() {
        this.events.forEach((event) => {
            window.removeEventListener(event, this.resetTimer);
        });
        clearTimeout(this.logoutTimer);
        clearTimeout(this.warningTimer);
        clearTimeout(this.keepAliveTimer); // Clear keep-alive timer
        window.removeEventListener("storage", this.syncLogout);
    },
    methods: {
        setTimers() {
            const remainingTime = this.timeout;
            const warningStart = remainingTime - this.warningTime;

            // Set the logout timer
            this.logoutTimer = setTimeout(this.logoutUser, remainingTime);

            // Set the warning timer for the warning time before logout
            this.warningTimer = setTimeout(() => {
                Vue.prototype.$eventBus.$emit('startLogoutCountdown', process.env.MIX_SESSION_WARNING_LIFETIME);
            }, warningStart);

            // Store the expiry time in localStorage
            localStorage.setItem("logoutTime", Date.now() + remainingTime);
        },
        logoutUser() {
            axios
                .post("/logout")
                .then(() => {
                    localStorage.setItem("logout", "true"); // Inform other tabs to log out
                    window.location.href = "/";
                })
                .catch(() => {
                    window.location.href = "/";
                });
        },
        resetTimer() {
            const remainingTime = localStorage.getItem("logoutTime") - Date.now();

            if (remainingTime <= this.keepAliveTime) {
                this.keepSessionAlive(); // Trigger keep-alive if within the last 5 minutes
            }

            clearTimeout(this.logoutTimer);
            clearTimeout(this.warningTimer);
            clearTimeout(this.keepAliveTimer); // Clear keep-alive timer

            Vue.prototype.$eventBus.$emit('closeLogoutCountdown');
            this.setTimers();
        },

        syncLogout(event) {
            if (event.key === "logout") {
                // Logout in other tabs if one tab logs out
                window.location.href = "/";
            } else if (event.key === "logoutTime") {
                const remainingTime = localStorage.getItem("logoutTime") - Date.now();
                const warningStart = remainingTime - this.warningTime;
                const keepAliveStart = remainingTime - this.keepAliveTime;

                clearTimeout(this.logoutTimer);
                clearTimeout(this.warningTimer);
                clearTimeout(this.keepAliveTimer);

                // Reset timers with the updated remaining time
                this.logoutTimer = setTimeout(this.logoutUser, remainingTime);

                if (remainingTime > this.warningTime) {
                    this.warningTimer = setTimeout(() => {
                        Vue.prototype.$eventBus.$emit('startLogoutCountdown', process.env.MIX_SESSION_WARNING_LIFETIME);
                    }, warningStart);
                }

                // Reset keep-alive timer
                if (remainingTime > this.keepAliveTime) {
                    this.keepAliveTimer = setTimeout(this.keepSessionAlive, keepAliveStart);
                }
            }
        },

        keepSessionAlive() {
            // Send a request to the server to keep the session alive
            axios.get('/keep-alive')
                .then(response => {
                    // Handle the response if necessary
                    console.log('Session kept alive:', response.data);
                })
                .catch(error => {
                    console.error('Error keeping session alive:', error);
                });
        }
    },
};
</script>
