var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "7",
                    xs: "8",
                    md: "5",
                    lg: "4",
                    xl: "3",
                  },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-center px-10 pt-5",
                          attrs: { justify: "center" },
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-img", {
                                staticClass: "mx-5 mb-0",
                                attrs: { src: "../images/rcms_logo.svg" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c("v-img", {
                                staticClass: "px-1 mt-n5",
                                attrs: { src: "../images/rcms_title.svg" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c("span", { staticClass: "text-center" }, [
                            _vm._v("\n              Please enter the "),
                            _c("strong", [_vm._v("OTP (One Time Pin)")]),
                            _vm._v(
                              " generated on your\n              Authenticator App. Ensure you submit the current one because it\n              refreshes every "
                            ),
                            _c("strong", [_vm._v("30 seconds")]),
                            _vm._v(".\n            "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-2",
                              attrs: { "lazy-validation": "" },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  return _vm.next.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("v-otp-input", {
                                attrs: {
                                  length: "6",
                                  rules: _vm.rules.otp,
                                  "hide-details": "",
                                },
                                model: {
                                  value: _vm.form.one_time_password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "one_time_password", $$v)
                                  },
                                  expression: "form.one_time_password",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-left" }, [
                                _c("small", { staticClass: "error--text" }, [
                                  _vm._v(_vm._s(_vm.text)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary mb-3 mt-8",
                                  attrs: {
                                    large: "",
                                    block: "",
                                    dark: "",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.next },
                                },
                                [
                                  _vm._v(
                                    "\n                Login\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 d-flex justify-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary--text",
                                  attrs: { small: "", text: "" },
                                  on: { click: _vm.close },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-chevron-left"),
                                  ]),
                                  _vm._v(
                                    "\n                Go back to login page\n              "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(
                        "\n            Copyright © " +
                          _vm._s(_vm.moment().format("YYYY")) +
                          " Pylon. All rights reserved.\n          "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }