export default class Gate {

    constructor(user) {
      this.user=user;
    }

    roles(){
      return this.user.roles;
    }

    permissions(){
      return this.user.permissions.map(permission=>permission);
    }

    role($roleName){
      return this.roles().includes($roleName);
    }


    can($permissionName){
      return this.permissions().includes($permissionName);
    }


  }
