<template>
  <v-app>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="7" xs="8" md="5" lg="4" xl="3">
          <v-card outlined>
            <a href="/">
              <v-row justify="center" class="text-center px-10 pt-5">
                <v-col>
                  <v-img class="mx-5 mb-0" src="/images/rcms_logo.svg"></v-img>
                </v-col>
                <v-col>
                  <v-img class="px-1 mt-n5" src="/images/rcms_title.svg"></v-img>
                </v-col>
              </v-row>
            </a>
            <v-card-text class="text-center">
              <span class="text-center">
                For your security kindly create a <strong>New Password</strong>.
              </span>
              <v-alert v-model="snackbar" dark dense type="error" transition="fade-transition">
                {{ text }}
              </v-alert>
              <v-form class="mt-2" ref="form" v-model="valid" lazy-validation @keydown.native.enter.prevent="newpassword">
                <v-text-field outlined required :append-icon="showp ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showp ? 'text' : 'password'" @click:append="showp = !showp" label="Enter a new passsword"
                  :rules="password" v-model="form.password" />
                <v-text-field outlined required :append-icon="showp2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showp2 ? 'text' : 'password'" @click:append="showp2 = !showp2" label="Re-enter a new password"
                  :rules="confirmpassword" v-model="form.password_confirmation" />
              </v-form>
              <span class="text-center">
                Password be atleast 8 characters, should contain Uppercase, Lowercase,
                Numbers, and Special characters
              </span>
              <v-btn @click="newpassword" class="mt-2 primary" large block :loading="loading">Reset Password
              </v-btn>
            </v-card-text>
          </v-card>
          <v-card-text class="text-center">
            <span>Copyright © {{ moment().format("YYYY") }} Pylon. All rights reserved.</span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    showp: false,
    showp2: false,
    valid: true,
    snackbar: false,
    text: "",
    form: {
      email: decodeURIComponent(document.location.search.split("=")[1]),
      token: document.location.pathname.split("/")[3],
      password: "",
      password_confirmation: "",
    },
    error: "",
  }),

  computed: {
    password() {
      return [
        (v) => !!v || "Password is required",
        (v) =>
          /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9_])/.test(
            v
          ) || "Please enter a valid password.",
      ];
    },
    confirmpassword() {
      return [
        (v) => !!v || "Re-enter password is required",
        (v) => v === this.form.password || "Your password does not match.",
      ];
    },
  },

  methods: {
    newpassword() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        axios
          .post("/password/reset", this.form)
          .then((response) => {
            this.backToLoginPage();
          })
          .catch((error) => {
            this.text = "This reset password link is invalid or expired.";
            this.error = error.response.data;
            this.snackbar = true;
            this.loading = false;
            clearTimeout(this.inteval);
            this.inteval = setTimeout(() => {
              this.snackbar = false;
            }, 5000);
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },

    backToLoginPage() {
      window.location.href = "/";
    },
  },

  mounted() { },
};
</script>
