var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "7",
                    xs: "8",
                    md: "5",
                    lg: "4",
                    xl: "3",
                  },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "text-center px-10 pt-5",
                          attrs: { justify: "center" },
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-img", {
                                staticClass: "mx-5 mb-0",
                                attrs: { src: "../images/rcms_logo.svg" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            [
                              _c("v-img", {
                                staticClass: "px-1 mt-n5",
                                attrs: { src: "../images/rcms_title.svg" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c("span", { staticClass: "text-center" }, [
                            _vm._v(" Login Credentials "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-2",
                              attrs: { "lazy-validation": "" },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return _vm.login.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  required: "",
                                  label: "Email Address",
                                  rules: _vm.rules.email,
                                  type: "email",
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                              _vm._v(" "),
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  "append-icon": _vm.showp
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showp ? "text" : "password",
                                  label: "Password",
                                  name: "Password",
                                  rules: _vm.rules.password,
                                  id: "password",
                                  autocomplete: "new-password",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showp = !_vm.showp
                                  },
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary mb-5",
                                  attrs: {
                                    large: "",
                                    block: "",
                                    dark: "",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.login },
                                },
                                [_c("span", [_vm._v("Login")])]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "mt-5",
                                  attrs: { href: "/password/reset" },
                                },
                                [_vm._v("Forgot Your Password?")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(
                        "Copyright © " +
                          _vm._s(_vm.moment().format("YYYY")) +
                          " Pylon. All rights reserved."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }