var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ma-1 d-flex flex-column align-center",
      staticStyle: { height: "100%" },
    },
    [
      _c("v-icon", { attrs: { size: "40" } }, [_vm._v("mdi-reload")]),
      _vm._v(" "),
      _c("span", [_vm._v("Failed to load")]),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "primary mt-5",
          attrs: { "x-small": "" },
          on: {
            click: function ($event) {
              return _vm.onFunctionComplete()
            },
          },
        },
        [_vm._v("Reload")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }