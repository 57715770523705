<template>
  <v-app>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="7" xs="8" md="5" lg="4" xl="3">
          <v-card outlined>
            <v-row justify="center" class="text-center px-10 pt-5">
              <v-col>
                <v-img class="mx-5 mb-0" src="../images/rcms_logo.svg"></v-img>
              </v-col>
              <v-col>
                <v-img class="px-1 mt-n5" src="../images/rcms_title.svg"></v-img>
              </v-col>
            </v-row>
            <v-card-text class="text-center">
              <span class="text-center"> Login Credentials </span>
              <!-- <v-alert v-model="snackbar" dark dense type="error" transition="fade-transition">
                {{ text }}
              </v-alert> -->

              <v-form class="mt-2" ref="form" v-model="valid" lazy-validation @keydown.native.enter="login">
                <v-text-field outlined required label="Email Address" :rules="rules.email" type="email"
                  v-model="form.email" />
                <v-text-field outlined :append-icon="showp ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showp ? 'text' : 'password'" @click:append="showp = !showp" label="Password" name="Password"
                  :rules="rules.password" id="password" v-model="form.password" autocomplete="new-password" />
                <v-btn class="primary mb-5" large block dark :loading="loading" @click="login">
                  <span>Login</span>
                </v-btn>

                <a href="/password/reset" class="mt-5">Forgot Your Password?</a>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card-text class="text-center">
            <span>Copyright © {{ moment().format("YYYY") }} Pylon. All rights reserved.</span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    showp: false,
    valid: true,
    snackbar: false,
    // text: "",
    inteval: "",
    form: {
      email: "",
      password: "",
    },
    rules: {
      email: [
        (v) => !!v || "Email address is required",
        (v) => !v || /.+@.+\..+/.test(v) || "Your email address is incorrect",
      ],
      password: [(v) => !!v || "Password is required"],
    },
  }),
  methods: {
    login() {
      this.loading = true;
      if (this.rules.email[2]) {
        this.rules.email.splice(2)
        this.rules.password.splice(1)
      }
      if (this.$refs.form.validate()) {
        axios
          .post("/login", this.form)
          .then((res) => {
            window.location.href = "/";
          })
          .catch((error) => {
            if (error.response.status === 419) {
              window.location.href = "/";
            }
            // this.text = "Error! Please re-try.";
            if (error.response.data.errors.email !== undefined) {
              this.rules.email.push((v) => false || error.response.data.errors.email[0])
              this.rules.password.push((v) => false || "")
              // this.text = error.response.data.errors.email[0];
            }
            this.$refs.form.validate()
            // this.snackbar = true;
            // clearTimeout(this.inteval);
            // this.inteval = setTimeout(() => {
            //   this.snackbar = false;
            // }, 5000);
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
  },
  mounted() { },
};
</script>
