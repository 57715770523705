var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    xs: "7",
                    sm: "6",
                    md: "5",
                    lg: "4",
                    xl: "5",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "text-center",
                      attrs: { justify: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("vue-qr", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false",
                              },
                            ],
                            ref: "qr",
                            attrs: {
                              qid: "testid",
                              text: _vm.qr_code,
                              logoSrc: "../images/philippine-logo1.png",
                              margin: 0,
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "qr_content" },
                            [
                              _c("v-responsive", {
                                staticClass: "qr-id mx-5 mb-5",
                                style: _vm.qrStyles,
                                attrs: { "aspect-ratio": 9 / 12 },
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "text" }, [
                                _vm._v("Amount to be paid:"),
                              ]),
                              _vm._v(" "),
                              _c("b", { staticClass: "amount" }, [
                                _vm._v(
                                  _vm._s(_vm._f("toCurrency")(_vm.amount))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-responsive", { staticClass: "overlay-1" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.merchant_name) +
                                    "\n              "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }