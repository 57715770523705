<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app clipped temporary>
      <router-link to="/" class="white--text" style="text-decoration: none">
        <v-img class="mx-10 mt-5" src="../images/rcms_logo.svg"></v-img>
      </router-link>
      <v-list nav dense>
        <v-list-item-group active-class="primary--text">
          <v-subheader v-if="$gate.can('View Dashboard Analytics') ||
            $gate.can('Nature of Tax') ||
            $gate.can('Summary Collection') ||
            $gate.can('Summary of Main Accounts') ||
            $gate.can('GF-RCD/TRUST/SEF') ||
            $gate.can('RCD') ||
            $gate.can('Real Property Tax Collection') ||
            $gate.can('Advance Real Property Tax Collection') ||
            $gate.can('Barangay Shares') ||
            $gate.can('Advance Barangay Shares') ||
            $gate.can('Report on RPT (Basic / SEF)')
          ">ANALYTICS</v-subheader>

          <v-list-item v-if="$gate.can('View Dashboard Analytics')" to="/">
            <v-list-item-icon>
              <v-icon color="primary">mdi-tablet-dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-if="$gate.can('Nature of Tax') ||
            $gate.can('Summary Collection') ||
            $gate.can('Summary of Main Accounts') ||
            $gate.can('GF-RCD/TRUST/SEF') ||
            $gate.can('RCD') ||
            $gate.can('Real Property Tax Collection') ||
            $gate.can('Advance Real Property Tax Collection') ||
            $gate.can('Barangay Shares') ||
            $gate.can('Advance Barangay Shares') ||
            $gate.can('Report on RPT (Basic / SEF)')
          ">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="primary">mdi-file-document-multiple-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Reports</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-if="$gate.can('Nature of Tax') ||
              $gate.can('Summary Collection') ||
              $gate.can('Summary of Main Accounts') ||
              $gate.can('GF-RCD/TRUST/SEF') ||
              $gate.can('RCD')" class="pl-7" to="/gen-summ">
              <v-list-item-icon>
                <v-icon color="primary">mdi-all-inclusive</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Collections</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$gate.can('Real Property Tax Collection') ||
              $gate.can('Advance Real Property Tax Collection') ||
              $gate.can('Barangay Shares') ||
              $gate.can('Advance Barangay Shares') ||
              $gate.can('Report on RPT (Basic / SEF)')" class="pl-7" to="/rpt">
              <v-list-item-icon>
                <v-icon color="primary">mdi-land-fields</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>RPT</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-subheader v-if="$gate.can('RPT Data Sync') ||
            $gate.can('View Transaction List') ||
            $gate.can('Create Transaction') ||
            $gate.can('View SHT List') ||
            $gate.can('View CTC List') ||
            $gate.can('View Nature of Tax List') ||
            $gate.can('View Collection Names') ||
            $gate.can('View GF-RCD/TRUST/SEF List') ||
            $gate.can('View Main Account List') ||
            $gate.can('View Summary of Collection List') ||
            $gate.can('View Employee List') ||
            $gate.can('View Cancelled OR List') ||
            $gate.can('View OR List') ||
            $gate.can('View Barangay List') ||
            $gate.can('View User List') ||
            $gate.can('View Log List') ||
            $gate.can('View Department List')
          ">APPLICATIONS</v-subheader>

          <v-list-group v-if="$gate.can('RPT Data Sync')">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="primary">mdi-database-sync</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Data Sync</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-if="$gate.can('RPT Data Sync')" class="pl-7" to="/rpt-data-matching">
              <v-list-item-icon>
                <v-icon color="primary">mdi-land-fields</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>RPT</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item v-if="$gate.can('View Transaction List')" to="/trans">
            <v-list-item-icon>
              <v-icon color="primary">mdi-bank-transfer</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Transactions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-if="$gate.can('Create Transaction') ||
            $gate.can('View SHT List') || $gate.can('View CTC List')">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="primary">mdi-cash-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cashier</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item class="pl-7" v-if="$gate.can('Create Transaction')" to="/cashier">
              <v-list-item-icon>
                <v-icon color="primary">mdi-cash</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cashier</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="pl-7" v-if="$gate.can('View SHT List')" to="/sht-list">
              <v-list-item-icon>
                <v-icon color="primary">mdi-cash-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>SHT</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="pl-7" v-if="$gate.can('View CTC List')" to="/ctc-list">
              <v-list-item-icon>
                <v-icon color="primary">mdi-cash-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>CTC</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-group v-if="$gate.can('View Nature of Tax List') ||
            $gate.can('View Collection Names') ||
            $gate.can('View GF-RCD/TRUST/SEF List') ||
            $gate.can('View Main Account List') ||
            $gate.can('View Summary of Collection List')">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="primary">mdi-format-list-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Collection Accounts</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-if="$gate.can('View Collection Names')" class="pl-7" to="/coll-accts">
              <v-list-item-icon>
                <v-icon color="primary">mdi-format-list-bulleted-square</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Collection Names</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$gate.can('View Nature of Tax List')" class="pl-7" to="/nature-of-tax">
              <v-list-item-icon>
                <v-icon color="primary">mdi-format-list-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Nature of Tax</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$gate.can('View Summary of Collection List')" class="pl-7" to="/summ-of-coll">
              <v-list-item-icon>
                <v-icon color="primary">mdi-format-list-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Summary of Collections</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$gate.can('View Main Account List')" class="pl-7" to="/main-accounts">
              <v-list-item-icon>
                <v-icon color="primary">mdi-format-list-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Summary of Main Accounts</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$gate.can('View GF-RCD/TRUST/SEF List')" class="pl-7" to="/gf-rcd-trust-sef">
              <v-list-item-icon>
                <v-icon color="primary">mdi-format-list-group</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>GF-RCD/TRUST/SEF</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>


          <v-list-item v-if="$gate.can('View Employee List')" to="/employees">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account-group-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Employee List</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="$gate.can('View Cancelled OR List')" to="/cancelled-or">
            <v-list-item-icon>
              <v-icon color="primary">mdi-file-document-remove-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Cancelled OR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="$gate.can('View OR List')" to="/or">
            <v-list-item-icon>
              <v-icon color="primary">mdi-script-text-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>OR Management</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="$gate.can('View Department List')" to="/department-list">
            <v-list-item-icon>
              <v-icon color="primary">mdi-lan</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Departments</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="$gate.can('View Barangay List')" to="/brgy-list">
            <v-list-item-icon>
              <v-icon color="primary">mdi-map-legend</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Barangays</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="$gate.can('View User List')" to="/user-mgt">
            <v-list-item-icon>
              <v-icon color="primary">mdi-account-group-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>User Management</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="$gate.can('View Role List')" to="/roles-permissions">
            <v-list-item-icon>
              <v-icon color="primary">mdi-shield-account-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Roles and Permissions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="$gate.can('View Log List')" to="/logs">
            <v-list-item-icon>
              <v-icon color="primary">mdi-file-document-edit-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Logs</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="primary" app clipped dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$gate.can('View Dashboard Analytics') ||
        $gate.can('Nature of Tax') ||
        $gate.can('Summary Collection') ||
        $gate.can('Summary of Main Accounts') ||
        $gate.can('GF-RCD/TRUST/SEF') ||
        $gate.can('RCD') ||
        $gate.can('Real Property Tax Collection') ||
        $gate.can('Advance Real Property Tax Collection') ||
        $gate.can('Barangay Shares') ||
        $gate.can('Advance Barangay Shares') ||
        $gate.can('Report on RPT (Basic / SEF)') ||
        $gate.can('RPT Data Sync') ||
        $gate.can('View Transaction List') ||
        $gate.can('Create Transaction') ||
        $gate.can('View SHT List') ||
        $gate.can('View CTC List') ||
        $gate.can('View Nature of Tax List') ||
        $gate.can('View Collection Names') ||
        $gate.can('View GF-RCD/TRUST/SEF List') ||
        $gate.can('View Main Account List') ||
        $gate.can('View Summary of Collection List') ||
        $gate.can('View Employee List') ||
        $gate.can('View Cancelled OR List') ||
        $gate.can('View OR List') ||
        $gate.can('View Barangay List') ||
        $gate.can('View User List') ||
        $gate.can('View Log List') ||
        $gate.can('View Department List')
      "></v-app-bar-nav-icon>
      <v-toolbar-title>Revenue Collection Management System (RCMS)</v-toolbar-title>
      <v-spacer></v-spacer>


      <notif v-if="$gate.can('Notifications')"></notif>

      <v-menu offset-y :close-on-content-click="closeOnClick">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="mr-1">
            <v-avatar color="white" size="40">
              <span class="primary--text text-h5 text-capitalize">{{ name[0] }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="text-center p-2">
          <v-avatar color="primary" class="mt-3" size="78">
            <span class="white--text text-h3 text-capitalize">{{ name[0] }}</span>
          </v-avatar>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6 text-capitalize">
                  {{ name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-capitalize">{{
                  role
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-btn class="mb-3" color="primary" small @click="route('/profile'), closeOnClick = true">Manage Account</v-btn>
                <v-btn color="secondary" dark small @click="logout" :loading="loading">Logout</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
        <snackbar></snackbar>
        <notifyAlert ref="sysnotifyAlert"></notifyAlert>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    closeOnClick: false,
    drawer: false,
    role: window.user.role,
    name: window.name,
    text: "",
  }),

  methods: {
    logout() {
      this.loading = true;
      axios.post("/logout").then((response) => {
        this.loading = false;
      });
    },

    route(route) {
      this.$router.push(route).catch(() => { });
    },
  },

  mounted() {
    Echo.channel("notification").listen("notify", (e) => {
      this.$refs.sysnotifyAlert.content({
        content: e.message,
        snackbar: true
      });
    });

    window.addEventListener("offline", (e) => {
      this.$refs.sysAlert.content({
        text: "You are currently offline.",
        snackbar: true,
        icon: "mdi-wifi-strength-off",
      });
    });

    window.addEventListener("online", (e) => {
      this.$refs.sysAlert.content({
        text: "Your internet connection was restored.",
        snackbar: true,
        icon: "mdi-wifi",
        timeout: 2000
      });
    });
  },
};
</script>
