var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { app: "", clipped: "", temporary: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "router-link",
            {
              staticClass: "white--text",
              staticStyle: { "text-decoration": "none" },
              attrs: { to: "/" },
            },
            [
              _c("v-img", {
                staticClass: "mx-10 mt-5",
                attrs: { src: "../images/rcms_logo.svg" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { nav: "", dense: "" } },
            [
              _c(
                "v-list-item-group",
                { attrs: { "active-class": "primary--text" } },
                [
                  _vm.$gate.can("View Dashboard Analytics") ||
                  _vm.$gate.can("Nature of Tax") ||
                  _vm.$gate.can("Summary Collection") ||
                  _vm.$gate.can("Summary of Main Accounts") ||
                  _vm.$gate.can("GF-RCD/TRUST/SEF") ||
                  _vm.$gate.can("RCD") ||
                  _vm.$gate.can("Real Property Tax Collection") ||
                  _vm.$gate.can("Advance Real Property Tax Collection") ||
                  _vm.$gate.can("Barangay Shares") ||
                  _vm.$gate.can("Advance Barangay Shares") ||
                  _vm.$gate.can("Report on RPT (Basic / SEF)")
                    ? _c("v-subheader", [_vm._v("ANALYTICS")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View Dashboard Analytics")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-tablet-dashboard"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Dashboard")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("Nature of Tax") ||
                  _vm.$gate.can("Summary Collection") ||
                  _vm.$gate.can("Summary of Main Accounts") ||
                  _vm.$gate.can("GF-RCD/TRUST/SEF") ||
                  _vm.$gate.can("RCD") ||
                  _vm.$gate.can("Real Property Tax Collection") ||
                  _vm.$gate.can("Advance Real Property Tax Collection") ||
                  _vm.$gate.can("Barangay Shares") ||
                  _vm.$gate.can("Advance Barangay Shares") ||
                  _vm.$gate.can("Report on RPT (Basic / SEF)")
                    ? _c(
                        "v-list-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [
                                            _vm._v(
                                              "mdi-file-document-multiple-outline"
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Reports"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            681184635
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm.$gate.can("Nature of Tax") ||
                          _vm.$gate.can("Summary Collection") ||
                          _vm.$gate.can("Summary of Main Accounts") ||
                          _vm.$gate.can("GF-RCD/TRUST/SEF") ||
                          _vm.$gate.can("RCD")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/gen-summ" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-all-inclusive")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Collections"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$gate.can("Real Property Tax Collection") ||
                          _vm.$gate.can(
                            "Advance Real Property Tax Collection"
                          ) ||
                          _vm.$gate.can("Barangay Shares") ||
                          _vm.$gate.can("Advance Barangay Shares") ||
                          _vm.$gate.can("Report on RPT (Basic / SEF)")
                            ? _c(
                                "v-list-item",
                                { staticClass: "pl-7", attrs: { to: "/rpt" } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-land-fields")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [_c("v-list-item-title", [_vm._v("RPT")])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("RPT Data Sync") ||
                  _vm.$gate.can("View Transaction List") ||
                  _vm.$gate.can("Create Transaction") ||
                  _vm.$gate.can("View SHT List") ||
                  _vm.$gate.can("View CTC List") ||
                  _vm.$gate.can("View Nature of Tax List") ||
                  _vm.$gate.can("View Collection Names") ||
                  _vm.$gate.can("View GF-RCD/TRUST/SEF List") ||
                  _vm.$gate.can("View Main Account List") ||
                  _vm.$gate.can("View Summary of Collection List") ||
                  _vm.$gate.can("View Employee List") ||
                  _vm.$gate.can("View Cancelled OR List") ||
                  _vm.$gate.can("View OR List") ||
                  _vm.$gate.can("View Barangay List") ||
                  _vm.$gate.can("View User List") ||
                  _vm.$gate.can("View Log List") ||
                  _vm.$gate.can("View Department List")
                    ? _c("v-subheader", [_vm._v("APPLICATIONS")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("RPT Data Sync")
                    ? _c(
                        "v-list-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-database-sync")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Data Sync"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1695757610
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm.$gate.can("RPT Data Sync")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/rpt-data-matching" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-land-fields")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [_c("v-list-item-title", [_vm._v("RPT")])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View Transaction List")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/trans" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-bank-transfer"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Transactions")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("Create Transaction") ||
                  _vm.$gate.can("View SHT List") ||
                  _vm.$gate.can("View CTC List")
                    ? _c(
                        "v-list-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-cash-multiple")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Cashier"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            165894545
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm.$gate.can("Create Transaction")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/cashier" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-cash")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Cashier"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$gate.can("View SHT List")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/sht-list" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-cash-marker")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [_c("v-list-item-title", [_vm._v("SHT")])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$gate.can("View CTC List")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/ctc-list" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-cash-marker")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [_c("v-list-item-title", [_vm._v("CTC")])],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View Nature of Tax List") ||
                  _vm.$gate.can("View Collection Names") ||
                  _vm.$gate.can("View GF-RCD/TRUST/SEF List") ||
                  _vm.$gate.can("View Main Account List") ||
                  _vm.$gate.can("View Summary of Collection List")
                    ? _c(
                        "v-list-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "primary" } },
                                          [_vm._v("mdi-format-list-text")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Collection Accounts"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3346667882
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm.$gate.can("View Collection Names")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/coll-accts" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [
                                          _vm._v(
                                            "mdi-format-list-bulleted-square"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Collection Names"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$gate.can("View Nature of Tax List")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/nature-of-tax" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-format-list-group")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Nature of Tax"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$gate.can("View Summary of Collection List")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/summ-of-coll" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-format-list-group")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Summary of Collections"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$gate.can("View Main Account List")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/main-accounts" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-format-list-group")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Summary of Main Accounts"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$gate.can("View GF-RCD/TRUST/SEF List")
                            ? _c(
                                "v-list-item",
                                {
                                  staticClass: "pl-7",
                                  attrs: { to: "/gf-rcd-trust-sef" },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "primary" } },
                                        [_vm._v("mdi-format-list-group")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("GF-RCD/TRUST/SEF"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View Employee List")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/employees" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-account-group-outline"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Employee List"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View Cancelled OR List")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/cancelled-or" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-file-document-remove-outline"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Cancelled OR")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View OR List")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/or" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-script-text-outline"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("OR Management"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View Department List")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/department-list" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-lan"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Departments")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View Barangay List")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/brgy-list" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-map-legend"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Barangays")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View User List")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/user-mgt" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-account-group-outline"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("User Management"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View Role List")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/roles-permissions" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-shield-account-outline"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v("Roles and Permissions"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.$gate.can("View Log List")
                    ? _c(
                        "v-list-item",
                        { attrs: { to: "/logs" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-file-document-edit-outline"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [_c("v-list-item-title", [_vm._v("Logs")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        { attrs: { color: "primary", app: "", clipped: "", dark: "" } },
        [
          _vm.$gate.can("View Dashboard Analytics") ||
          _vm.$gate.can("Nature of Tax") ||
          _vm.$gate.can("Summary Collection") ||
          _vm.$gate.can("Summary of Main Accounts") ||
          _vm.$gate.can("GF-RCD/TRUST/SEF") ||
          _vm.$gate.can("RCD") ||
          _vm.$gate.can("Real Property Tax Collection") ||
          _vm.$gate.can("Advance Real Property Tax Collection") ||
          _vm.$gate.can("Barangay Shares") ||
          _vm.$gate.can("Advance Barangay Shares") ||
          _vm.$gate.can("Report on RPT (Basic / SEF)") ||
          _vm.$gate.can("RPT Data Sync") ||
          _vm.$gate.can("View Transaction List") ||
          _vm.$gate.can("Create Transaction") ||
          _vm.$gate.can("View SHT List") ||
          _vm.$gate.can("View CTC List") ||
          _vm.$gate.can("View Nature of Tax List") ||
          _vm.$gate.can("View Collection Names") ||
          _vm.$gate.can("View GF-RCD/TRUST/SEF List") ||
          _vm.$gate.can("View Main Account List") ||
          _vm.$gate.can("View Summary of Collection List") ||
          _vm.$gate.can("View Employee List") ||
          _vm.$gate.can("View Cancelled OR List") ||
          _vm.$gate.can("View OR List") ||
          _vm.$gate.can("View Barangay List") ||
          _vm.$gate.can("View User List") ||
          _vm.$gate.can("View Log List") ||
          _vm.$gate.can("View Department List")
            ? _c("v-app-bar-nav-icon", {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.drawer = !_vm.drawer
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("v-toolbar-title", [
            _vm._v("Revenue Collection Management System (RCMS)"),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.$gate.can("Notifications") ? _c("notif") : _vm._e(),
          _vm._v(" "),
          _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                "close-on-content-click": _vm.closeOnClick,
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            { staticClass: "mr-1", attrs: { icon: "" } },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-avatar",
                            { attrs: { color: "white", size: "40" } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "primary--text text-h5 text-capitalize",
                                },
                                [_vm._v(_vm._s(_vm.name[0]))]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "text-center p-2" },
                [
                  _c(
                    "v-avatar",
                    {
                      staticClass: "mt-3",
                      attrs: { color: "primary", size: "78" },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "white--text text-h3 text-capitalize" },
                        [_vm._v(_vm._s(_vm.name[0]))]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "text-h6 text-capitalize" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.name) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-subtitle",
                                { staticClass: "text-capitalize" },
                                [_vm._v(_vm._s(_vm.role))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-3",
                                  attrs: { color: "primary", small: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.route("/profile"),
                                        (_vm.closeOnClick = true)
                                    },
                                  },
                                },
                                [_vm._v("Manage Account")]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "secondary",
                                    dark: "",
                                    small: "",
                                    loading: _vm.loading,
                                  },
                                  on: { click: _vm.logout },
                                },
                                [_vm._v("Logout")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("router-view"),
              _vm._v(" "),
              _c("snackbar"),
              _vm._v(" "),
              _c("notifyAlert", { ref: "sysnotifyAlert" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }