<template>
    <div>
        <v-menu offset-y :close-on-content-click="closeOnClick" width="420px">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="mr-1" @click="openNotif()">
                    <v-badge dense :content="unreadCount" :value="unreadCount" color="red" overlap>
                        <v-icon>
                            mdi-bell
                        </v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <v-card v-if="loading" width="350" class="mx-auto" style="max-height: 490px; overflow-y: auto;">
                <v-toolbar elevation="0" class="mt-5">
                    <v-toolbar-title>
                        <v-skeleton-loader class="mt-5" type="heading" height="60" width="250"></v-skeleton-loader>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-skeleton-loader type="avatar" height="60"></v-skeleton-loader>
                    <template v-slot:extension>
                        <v-skeleton-loader class="mr-3 mt-2" type="button" height="30"></v-skeleton-loader>
                        <v-skeleton-loader class="mr-3 mt-2" type="button" height="30"></v-skeleton-loader>
                    </template>
                </v-toolbar>
                <v-skeleton-loader class="ml-3 mt-5" type="text" height="30" width="70"></v-skeleton-loader>
                <div v-for="(item) in 4">
                    <v-skeleton-loader v-bind="attrs" type="list-item-avatar-three-line">
                    </v-skeleton-loader>
                </div>
            </v-card>
            <v-card v-else width="350" class="mx-auto" style="max-height: 490px; overflow-y: auto;"
                @scroll="handleScroll">
                <v-toolbar elevation="0">
                    <v-toolbar-title>Notifications</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" class="mt-2" color="primary" fab text elevation="0"
                                @click="markAllAsRead">
                                <v-icon> mdi-email-open-outline </v-icon>
                            </v-btn>
                        </template>
                        <span>Mark all as read</span>
                    </v-tooltip>
                    <template v-slot:extension>
                        <v-btn small :rounded="activeButton === 'all'" :text="activeButton !== 'all'" class="mr-2"
                            :class="{ 'primary': activeButton === 'all' }" @click="setActiveButton('all')">
                            All
                        </v-btn>
                        <v-btn small :rounded="activeButton === 'unread'" :text="activeButton !== 'unread'" class="mr-2"
                            :class="{ 'primary': activeButton === 'unread' }" @click="setActiveButton('unread')">
                            Unread
                        </v-btn>
                    </template>
                </v-toolbar>
                <div v-if="loadingFailed" class="my-10 d-flex justify-center align-center">
                    <Failed :onFunctionComplete="fetchNotifs"></Failed>
                </div>
                <div v-else-if="formatted_notifs.length > 0">
                    <v-list two-line>
                        <div v-for="(item, index) in formatted_notifs" :key="index">
                            <v-subheader v-if="item.isHeader" v-text="item.header"></v-subheader>
                            <v-divider v-else-if="item.isDivider" :inset="item.inset"></v-divider>
                            <v-list-item v-else :class="{ 'grey lighten-2': !item.read }" @click="markAsRead(item)">
                                <v-list-item-avatar>
                                    <v-avatar color="primary" size="40">
                                        <span class="white--text text-capitalize">{{ item.name[0] }}</span>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <p class="body-2" v-text="item.subtitle"></p>
                                    <p class="ma-0 pa-0 caption">{{ item.created_at | relativeTime }}</p>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                        <div v-if="adding" v-for="(item) in 2">
                            <v-skeleton-loader v-bind="attrs" type="list-item-avatar-three-line">
                            </v-skeleton-loader>
                        </div>
                        <div v-if="page >= last_page">
                            <p class="mt-5 text-center body-2">No more notifications</p>
                        </div>
                    </v-list>
                </div>
                <div v-else class="pa-4">
                    <p class="text-center body-2">You have no notifications</p>
                </div>
            </v-card>
        </v-menu>
    </div>
</template>
<script>
import notificationSound from '../../../../public/sound/notif.mp3';
import Failed from './../alert/failed.vue';

export default {
    components: {
        Failed
    },

    data() {
        return {
            loadingFailed: false,

            attrs: {
                boilerplate: true,
                elevation: 0,
            },
            closeOnClick: false,
            unreadCount: 0,
            loading: true,
            notificationAudio: new Audio(notificationSound),
            activeButton: 'all',
            notifs: [],
            unreadNotifs: [],
            formatted_notifs: [],
            adding: false,
            page: 1,
            last_page: "",
        }
    },

    methods: {
        markAllAsRead() {
            axios
                .get("/mark-all-as-read")
                .then((res) => {
                    if (this.activeButton == 'all') {
                        this.notifs.forEach(item => {
                            item.read = true;
                        });
                    } else {
                        this.unreadNotifications()
                    }

                })
                .catch((error) => { });
        },

        async markAsRead(selected_item) {
            let actualIndex = -1;
            let items = [];

            if (this.activeButton === 'all') {
                items = this.notifs;
            } else {
                items = this.unreadNotifs;
            }

            if (selected_item) {
                if (this.activeButton === 'all') {
                    actualIndex = this.notifs.findIndex(item => item.id === selected_item.id);
                } else {
                    actualIndex = this.unreadNotifs.findIndex(item => item.id === selected_item.id);
                }

                if (actualIndex !== -1) {
                    this.$router.push(selected_item.link).catch(() => { });
                    this.closeOnClick = true;

                    if (!selected_item.read) {
                        try {
                            await axios.post("/mark-as-read", { id: selected_item.id });
                            selected_item.read = true;

                            // Refresh unread notifications list
                            await this.unreadNotifications();
                        } catch (error) {
                            console.error("Error marking notification as read:", error);
                        }
                    }

                    this.activeButton = 'all';
                } else {
                    console.error("Notification not found at the provided index.");
                }
            } else {
                console.error("Selected item not found.");
            }
        },

        async setActiveButton(button) {
            this.activeButton = button;
            this.loading = true;

            if (this.activeButton == 'all') {
                try {
                    const notificationListSuccess = await this.notificationList();
                    if (notificationListSuccess) {
                        this.$nextTick(() => {
                            this.filteredItems();
                            this.loading = false;
                        });
                    } else {
                        this.loading = false;
                        // Handle unsuccessful fetch here if needed
                    }
                } catch (error) {
                    this.loading = false;
                    console.error("Error fetching notification list:", error);
                }
            } else {
                try {
                    const unreadNotificationsSuccess = await this.unreadNotifications();
                    if (unreadNotificationsSuccess) {
                        this.$nextTick(() => {
                            this.filteredUnreadItems();
                            this.loading = false;
                        });
                    } else {
                        this.loading = false;
                        // Handle unsuccessful fetch here if needed
                    }
                } catch (error) {
                    this.loading = false;
                    console.error("Error fetching unread notifications:", error);
                }
            }
        },


        handleScroll(event) {
            const container = event.target;
            if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
                this.onScrollToBottom();
            }
        },

        onScrollToBottom() {
            this.adding = false;
            if (this.page <= this.last_page) {
                this.adding = true;
                this.page++
                if (this.activeButton == 'all') {
                    axios
                        .post("/notif-list?page=" + this.page)
                        .then((res) => {
                            this.adding = false;
                            this.notifs.push(...res.data.data);
                            this.last_page = res.data.last_page;
                            this.filteredItems();
                        })
                        .catch((error) => { });
                } else {
                    axios
                        .post("/unread-notifs?page=" + this.page)
                        .then((res) => {
                            this.adding = false;
                            this.unreadNotifs.push(...res.data.data);
                            this.last_page = res.data.last_page;
                            this.filteredUnreadItems();
                        })
                        .catch((error) => { });
                }
            } else {
                this.adding = false;
            }

        },

        filteredUnreadItems() {
            const currentDate = new Date();
            const newItems = [];
            const earlierItems = [];

            // Filter only unread items
            this.unreadNotifs.forEach(item => {
                if (!item.read) {
                    const itemDate = new Date(item.time);
                    if (itemDate >= new Date(currentDate.setDate(currentDate.getDate() - 1))) {
                        newItems.push(item);
                    } else {
                        earlierItems.push(item);
                    }
                }
            });

            const result = [];

            if (newItems.length > 0) {
                result.push({ isHeader: true, header: 'New' });
                result.push(...newItems);
            }

            if (earlierItems.length > 0) {
                result.push({ isHeader: true, header: 'Earlier' });
                result.push(...earlierItems);
            }

            this.formatted_notifs = result;
        },

        filteredItems() {
            const currentDate = new Date();
            const newItems = [];
            const earlierItems = [];

            this.notifs.forEach(item => {
                const itemDate = new Date(item.time);
                if (itemDate >= new Date(currentDate.setDate(currentDate.getDate() - 1))) {
                    newItems.push(item);
                } else {
                    earlierItems.push(item);
                }
            });

            const result = [];

            if (newItems.length > 0) {
                result.push({ isHeader: true, header: 'New' });
                result.push(...newItems);
            }

            if (earlierItems.length > 0) {
                result.push({ isHeader: true, header: 'Earlier' });
                result.push(...earlierItems);
            }

            this.formatted_notifs = result;
        },

        updateUnreadCount() {
            this.unreadCount = this.unreadNotifs.length;
        },

        openNotif() {
            this.closeOnClick = false;
            this.fetchNotifs();
        },

        async fetchNotifs() {
            this.loading = true;
            this.loadingFailed = false;
            this.adding = false;
            this.activeButton = 'all';

            try {
                const notificationListSuccess = await this.notificationList();
                const unreadNotificationsSuccess = await this.unreadNotifications();
                if (notificationListSuccess && unreadNotificationsSuccess) {
                    this.$nextTick(() => {
                        this.filteredItems();
                        this.loading = false;
                    });
                } else {
                    this.loading = false;
                    this.loadingFailed = true;
                    // Handle unsuccessful fetch here if needed
                }
            } catch (error) {
                this.loading = false;
                this.loadingFailed = true;
                console.error("Error fetching notifications:", error);
            }
        },

        async unreadNotifications() {
            this.loading_list = true;
            try {
                this.formatted_notifs = [];
                this.page = 1;
                const res = await axios.post(`/unread-notifs?page=${this.page}`);
                this.unreadCount = res.data.total;
                this.unreadNotifs = res.data.data;
                this.last_page = res.data.last_page;
                this.loading_list = false;
                return true;
            } catch (error) {
                console.error("Error fetching unread notifications:", error);
                return false;
            }
        },

        async notificationList() {
            this.loading_list = true;
            try {
                this.formatted_notifs = [];
                this.page = 1;
                const res = await axios.post(`/notif-list?page=${this.page}`);
                this.notifs = res.data.data;
                this.last_page = res.data.last_page;
                this.loading_list = false;
                return true;
            } catch (error) {
                console.error("Error fetching notification list:", error);
                return false;
            }
        },

        playNotificationSound() {
            this.notificationAudio.play();
        },
    },

    mounted() {
        this.fetchNotifs();

        Echo.channel("notification").listen("notify", (e) => {
            this.playNotificationSound();
            this.unreadNotifs.unshift(e.message);
            this.notifs.unshift(e.message);
            this.filteredItems();
            this.filteredUnreadItems();
            this.updateUnreadCount();
        });
    },
};
</script>
