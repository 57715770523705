<template>
  <v-app>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="7" xs="8" md="5" lg="4" xl="3">
          <v-row justify="center" class="text-center mb-5 px-10 pt-5">
            <v-col>
              <v-img class="mx-5 mb-0" src="/images/rcms_logo.svg"></v-img>
            </v-col>
            <v-col>
              <v-img class="px-1 mt-n5" src="/images/rcms_title.svg"></v-img>
            </v-col>
          </v-row>
          <v-card outlined>
            <v-card-text class="text-center">
              <v-icon color="error" size="150">mdi-alert-circle-outline</v-icon><br />
              <p class="mb-10 text-center text-h6"> Payment Expired</p>
              <v-divider class="mb-3"></v-divider>
              <p class="text-center"> Reference Number: {{ this.ref }} </p>
            </v-card-text>
          </v-card>
          <v-card-text class="text-center">
            <span>Copyright © {{ moment().format("YYYY") }} Pylon. All rights reserved.</span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  computed: {
    ref() {
      return JSON.parse(atob(window.location.href.split('//')[1].split('/')[3])).referenceNo;
    }
  },
};
</script>
