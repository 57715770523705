<template>
  <v-snackbar transition="scroll-x-reverse-transition" min-width="50" class="mt-16" v-model="snackbar" top right
    :timeout="timeout">
    <v-layout align-center pr-4>
      <v-icon class="pr-3">{{ icon }}</v-icon>
      <v-layout column>
        <div>{{ text }}</div>
      </v-layout>
    </v-layout>
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="snackbar = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import moment from 'moment';

export default {
  data: () => ({
    snackbar: false,
    text: "",
    timeout: 5000,
    icon: "",
    countdown: 30, // Countdown starting point
    showCountdown: false, // Determines whether to show countdown
    interval: null, // Interval for countdown
  }),

  created() {
    this.$root.$eventBus.$on('showSnackbar', this.show);
    this.$root.$eventBus.$on('startLogoutCountdown', this.startCountdown);
    this.$root.$eventBus.$on('closeLogoutCountdown', this.closeLogoutCountdown);
  },

  methods: {
    show(response) {
      if (!response || typeof response.status === 'undefined') {
        this.text = "An unexpected error occurred.";
        this.icon = "mdi-alert-circle";
        this.snackbar = true;
        return;
      }

      const status = response.status;

      // Define a mapping of status codes to responses
      const statusMap = {
        // Informational Responses (1xx)
        100: { text: "Continue. The initial part of the request has been received.", icon: "mdi-information-outline" },
        101: { text: "Switching Protocols. The server is switching protocols.", icon: "mdi-swap-horizontal" },
        102: { text: "Processing. The request is being processed.", icon: "mdi-progress-clock" },

        // Successful Responses (2xx)
        200: { text: response.data.message || "Action executed successfully.", icon: "mdi-check-circle" },
        201: { text: response.data.message || "Resource created successfully.", icon: "mdi-check-circle" },
        202: { text: "Accepted. The request has been accepted for processing.", icon: "mdi-clock-outline" },
        204: { text: "No Content. The server successfully processed the request.", icon: "mdi-check-circle-outline" },

        // Redirection Responses (3xx)
        301: { text: "Moved Permanently. The resource has been moved permanently.", icon: "mdi-arrow-right-bold" },
        302: { text: "Found. The resource is temporarily available at a different URL.", icon: "mdi-arrow-right-bold-outline" },

        // Client Error Responses (4xx)
        400: { text: "Bad Request. Please check your input.", icon: "mdi-alert-circle" },
        401: { text: "Unauthorized. Please log in.", icon: "mdi-account-lock", action: () => window.location.href = "/login" },
        403: { text: "Forbidden. You don't have permission.", icon: "mdi-block-helper" },
        404: { text: "Not Found. The resource could not be found.", icon: "mdi-magnify-close" },
        419: { text: "Session expired or CSRF token mismatch. The page will now reload.", icon: "mdi-clock", action: () => window.location.reload() },
        422: {
          text: response.data.errors
            ? Object.values(response.data.errors)[0][0]
            : response.data.message
              ? response.data.message
              : "Invalid Data. Please check your input.",
          icon: "mdi-alert-circle"
        },

        // Server Error Responses (5xx)
        500: { text: "Server Error. Please try again later.", icon: "mdi-server" },
        503: { text: "Service Unavailable. The server is currently unavailable.", icon: "mdi-cloud-off-outline" },
      };

      // Fallback for unhandled status codes
      const fallback = { text: response.data.message || "An unexpected error occurred.", icon: "mdi-alert-circle" };

      // Retrieve the appropriate status entry or use the fallback
      const { text, icon, action } = statusMap[status] || fallback;

      // Set the text and icon
      this.text = text;
      this.icon = icon;

      // Execute any special action if available (e.g., redirect)
      if (action) {
        action();
      }

      // Show the snackbar
      this.snackbar = true;
    },

    startCountdown(countdown = 5) {
      this.countdown = countdown * 60; // Total time in seconds (e.g., 5 minutes * 60 = 300 seconds)
      this.showCountdown = true;
      this.snackbar = true;
      this.icon = "mdi-alert";
      this.text = "Session about to expire!";
      this.timeout = this.countdown * 1000;

      // Clear any previous countdown interval
      clearInterval(this.interval);

      // Update the text with the formatted countdown using moment
      this.updateCountdownText();

      // Start a new interval that decrements the countdown every second
      this.interval = setInterval(() => {
        this.countdown--;

        // Update the text with the formatted countdown each second using moment
        this.updateCountdownText();

        // When the countdown reaches zero, clear the interval and optionally hide the Snackbar
        if (this.countdown <= 0) {
          clearInterval(this.interval);
          this.snackbar = false; // Hide the Snackbar when the countdown finishes
          // You may trigger logout here if needed
        }
      }, 1000); // The countdown decreases every second
    },

    // Helper method to format the countdown using moment.js as MM:SS
    updateCountdownText() {
      const formattedCountdown = moment.utc(this.countdown * 1000).format('mm:ss');
      this.text = `You will be logged out in ${formattedCountdown}`;
    },

    closeLogoutCountdown() {
      if (this.showCountdown) {
        this.snackbar = false;
      }
    },

    content(data) {
      if (this.snackbar === true) {
        this.snackbar = false;
      }
      clearTimeout(this.interval);
      this.interval = setTimeout(() => {
        this.text = data.text;
        this.icon = data.icon;
        this.snackbar = data.snackbar;
        this.timeout = data.timeout;
      }, 1000);
    },
  },
};
</script>
