<template>
  <v-snackbar transition="scroll-x-reverse-transition" right width="70" v-model="snackbar" :timeout="timeout">
    <v-layout align-center pr-4>
      <v-avatar color="primary" size="40">
        <span class="white--text text-capitalize">{{ getInitial(text.name) }}</span>
      </v-avatar>
      <v-layout column class="ml-5 mt-3">
        <p class="body-2">{{ text.subtitle }} </br> <small>{{ text.time | relativeTime }}</small></p>
      </v-layout>
    </v-layout>
    <template v-slot:action="{ attrs }">
      <v-btn icon v-bind="attrs" @click="view">
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    interval: null,
    snackbar: false,
    text: {
      name: '',
      subtitle: '',
      time: '',
      link: '',
    },
    timeout: 10000,
  }),

  computed: {

  },

  methods: {
    view() {
      this.snackbar = false
      this.$router.push(this.text.link).catch(() => { });
    },

    getInitial(name) {
      return name ? name[0] : '';
    },

    content(data) {
      if (this.snackbar === true) {
        this.snackbar = false;
      }
      clearTimeout(this.interval);
      this.interval = setTimeout(() => {
        this.text = data.content;
        this.snackbar = data.snackbar;
      }, 1000);
    },
  },
};
</script>