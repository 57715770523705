var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "7",
                    xs: "8",
                    md: "5",
                    lg: "4",
                    xl: "3",
                  },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "a",
                        { attrs: { href: "/" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "text-center px-10 pt-5",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-img", {
                                    staticClass: "mx-5 mb-0",
                                    attrs: { src: "../images/rcms_logo.svg" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-img", {
                                    staticClass: "px-1 mt-n5",
                                    attrs: { src: "../images/rcms_title.svg" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c("span", { staticClass: "text-center" }, [
                            _vm._v("\n              We will send a "),
                            _c("strong", [_vm._v("Reset Password Link")]),
                            _vm._v(" in this email address.\n            "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                dark: "",
                                dense: "",
                                type: "error",
                                transition: "fade-transition",
                              },
                              model: {
                                value: _vm.snackbar,
                                callback: function ($$v) {
                                  _vm.snackbar = $$v
                                },
                                expression: "snackbar",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.text) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-2",
                              attrs: {
                                "lazy-validation": "",
                                disabled: _vm.disable,
                              },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  return _vm.send.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  required: "",
                                  label: "Email Address",
                                  rules: _vm.rules.email,
                                  type: "email",
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2 primary",
                                  attrs: {
                                    small: _vm.$vuetify.breakpoint.smAndDown,
                                    large: _vm.$vuetify.breakpoint.mdAndUp,
                                    block: "",
                                    loading: _vm.loading,
                                    disabled: _vm.disable,
                                  },
                                  on: { click: _vm.send },
                                },
                                [
                                  _c("span", [
                                    _vm._v("Send Password Reset Link"),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n              Click RESEND if you haven't received the email within (" +
                                _vm._s(_vm.seconds) +
                                ")\n              seconds.\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-2 primary--text",
                              attrs: {
                                small: "",
                                text: "",
                                loading: _vm.resendLoading,
                              },
                              on: { click: _vm.resend },
                            },
                            [
                              _vm._v(
                                "\n              RESEND LINK\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(
                        "Copyright © " +
                          _vm._s(_vm.moment().format("YYYY")) +
                          " Pylon. All rights reserved."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }