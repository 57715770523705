/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import vuetify from './plugins/vuetify'
import Vue from 'vue'
import router from './router'
import moment from 'moment'
import Gate from "./gate"
import ErrorComponent from './components/alert/snackbar.vue';

Vue.prototype.moment = moment
Vue.prototype.$gate = new Gate(window.user);
window.Vue = require('vue').default;

Vue.prototype.$eventBus = new Vue();

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('ErrorComponent', ErrorComponent);
Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('main-app', require('./components/app.vue').default);
Vue.component('login', require('./components/auth/login.vue').default);
Vue.component('email', require('./components/auth/email.vue').default);
Vue.component('reset', require('./components/auth/reset.vue').default);
Vue.component('otp', require('./components/auth/otp.vue').default);
Vue.component('new_password', require('./components/auth/newpassword.vue').default);
Vue.component('snackbar', require('./components/alert/snackbar.vue').default);
Vue.component('notifyAlert', require('./components/alert/notifyAlert.vue').default);
Vue.component('payment', require('./components/cashier/payment.vue').default);
Vue.component('autologout', require('./components/auth/autologout.vue').default);

Vue.component('confirmation', require('./components/callback/confirmation.vue').default);
Vue.component('expired', require('./components/callback/expired.vue').default);
Vue.component('failed', require('./components/callback/failed.vue').default);
Vue.component('success', require('./components/callback/success.vue').default);

Vue.component('notif', require('./components/notif/notif.vue').default);

Vue.filter('formatString', function (value) {
    if (!value) return '';
    return value
        .replace(/_/g, ' ')
        .replace(/\b\w/g, char => char.toUpperCase());
});

Vue.filter('toCurrency', function (value) {
    var formatter = new Intl.NumberFormat('fil-PH', {
        style: 'currency',
        currency: 'PHP'
    });
    return formatter.format(value);
});

Vue.filter('toDate', function (value) {
    return moment(value).format("MM-DD-YYYY hh:mm a")
});

Vue.filter('dbDate', function (value) {
    return moment(value).format("YYYY-MM-DD HH:mm:ss");
});


Vue.filter('toFullDate', function (value) {
    return moment(value).format("dddd, MMMM D, YYYY h:mm:ss a")
});

Vue.filter('dueDate', function (value) {
    if (value == "null null") {
        return;
    }

    return moment(value, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");
});

var converter = require('number-to-words');
Vue.filter('toWords', function (value) {
    if (!value) return '';
    var value = (Math.round(value * 100) / 100).toFixed(2);

    if (typeof value.toString().split(".")[0] !== 'undefined') {
        var pesos = value.toString().split(".")[0];
        if (value > 1) {
            var php = " pesos"
        } else {
            var php = " peso"
        }
        var pesos1 = converter.toWords(pesos) + php;
        var words = pesos1;
    }

    if (typeof value.toString().split(".")[1] !== 'undefined') {
        var cetavos = value.toString().split(".")[1];
        if (cetavos > 1) {
            if (cetavos > 1) {
                var cents = " centavos"
            } else {
                var cents = " centavo"
            }
            var cetavos1 = converter.toWords(cetavos) + cents;
            var words = pesos1 + " and " + cetavos1;
        }
    }

    words = words + " only";

    const name = words.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toLowerCase()
    );
    const wordstoLowerCase = name.split(" ");
    return wordstoLowerCase
        .map((wordstoLowerCase) => {
            return wordstoLowerCase[0].toUpperCase() + wordstoLowerCase.substring(1);
        })
        .join(" ");

})

Vue.filter('toDateOnly', function (value) {
    return moment(value).format("MM-DD-YYYY")
});

Vue.filter('toDay', function (value) {
    return moment(value).format('dddd');
});

Vue.filter('toFormatNumber', function (num) {
    if (num >= 1000000000000000) {
        return (num / 1000000000000000).toFixed(1).replace(/\.0$/, '') + 'Q';
    }
    if (num >= 1000000000000) {
        return (num / 1000000000000).toFixed(1).replace(/\.0$/, '') + 'T';
    }
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num.toFixed(1).replace(/\.0$/, '');
})

Vue.filter('relativeTime', function (value) {
    return moment(value).startOf('second').fromNow();
});

Vue.filter('capitalize', function (value) {
    if (!value) return '';
    value = value.toString().toLowerCase();
    return value.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });
});



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    vuetify,
    router,
    el: '#app',
});
