<template>
    <div class="ma-1 d-flex flex-column align-center" style="height: 100%;">
        <v-icon size="40">mdi-reload</v-icon> 
        <span>Failed to load</span>
        <v-btn x-small class="primary mt-5" @click="onFunctionComplete()">Reload</v-btn>
    </div>
</template>

<script>

export default {
    props: {
      onFunctionComplete: Function
    },
};
</script>