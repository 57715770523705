<template>
  <v-app>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="7" xs="8" md="5" lg="4" xl="3">
          <v-card outlined>
            <a href="/">
              <v-row justify="center" class="text-center px-10 pt-5">
                <v-col>
                  <v-img class="mx-5 mb-0" src="../images/rcms_logo.svg" />
                </v-col>
                <v-col>
                  <v-img class="px-1 mt-n5" src="../images/rcms_title.svg" />
                </v-col>
              </v-row>
            </a>
            <v-card-text class="text-center">
              <span class="text-center">
                For your security, kindly change your
                <strong>Temporary Password</strong>.
              </span>
              <v-alert v-if="text" v-model="snackbar" dark dense type="error" transition="fade-transition">
                {{ text }}
              </v-alert>
              <v-form class="mt-2" ref="form" v-model="valid" lazy-validation
                @keydown.native.enter.prevent="newpassword">
                <v-text-field outlined required :append-icon="showp ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showp ? 'text' : 'password'" @click:append="showp = !showp" label="Enter a new password"
                  :rules="passwordRules" v-model="form.password" />
                <v-text-field outlined required :append-icon="showp2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showp2 ? 'text' : 'password'" @click:append="showp2 = !showp2" label="Re-enter a new password"
                  :rules="confirmPasswordRules" v-model="form.newpassword" />
              </v-form>
              <span class="text-center">
                Password must be at least 8 characters long and should contain an uppercase letter, a lowercase letter,
                a number, and a special character.
              </span>
              <v-btn @click="newpassword" class="primary mt-2" large block :loading="loading">
                Change Password
              </v-btn>
              <v-col cols="12" class="pb-0 d-flex justify-center">
                <v-btn class="primary--text" small text @click="close">
                  <v-icon left>mdi-chevron-left</v-icon>Go back to login page
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>
          <v-card-text class="text-center">
            <span>Copyright © {{ moment().format("YYYY") }} Pylon. All rights reserved.</span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    showp: false,
    showp2: false,
    valid: true,
    snackbar: false,
    text: "",
    form: {
      password: "",
      newpassword: "",
    },
    error: "",
  }),

  computed: {
    passwordRules() {
      return [
        (v) => !!v || "Password is required.",
        (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(v) ||
          "Password must be at least 8 characters long, and include uppercase, lowercase, number, and special character.",
        (v) =>
          (v && v.length <= 200) || "Password must be less than 200 characters.",
      ];
    },
    confirmPasswordRules() {
      return [
        (v) => !!v || "Re-entering the password is required.",
        (v) => v === this.form.password || "Passwords do not match.",
      ];
    },
  },

  methods: {
    close() {
      axios.post("/logout").then(() => {
        window.location.href = "/";
      });
    },

    newpassword() {
      this.loading = true;
      this.text = "";

      if (this.$refs.form.validate()) {
        axios
          .post("/password-update", this.form)
          .then(() => {
            window.location.reload();
          })
          .catch((error) => {
            if ([401, 419].includes(error.response.status)) {
              window.location.href = "/";
            }
          });
      }
      this.loading = false;
    },
  },
};
</script>
