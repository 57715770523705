var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        transition: "scroll-x-reverse-transition",
        right: "",
        width: "70",
        timeout: _vm.timeout,
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  { attrs: { icon: "" }, on: { click: _vm.view } },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "pr-4": "" } },
        [
          _c("v-avatar", { attrs: { color: "primary", size: "40" } }, [
            _c("span", { staticClass: "white--text text-capitalize" }, [
              _vm._v(_vm._s(_vm.getInitial(_vm.text.name))),
            ]),
          ]),
          _vm._v(" "),
          _c("v-layout", { staticClass: "ml-5 mt-3", attrs: { column: "" } }, [
            _c("p", { staticClass: "body-2" }, [
              _vm._v(_vm._s(_vm.text.subtitle) + " "),
              _c("br"),
              _vm._v(" "),
              _c("small", [
                _vm._v(_vm._s(_vm._f("relativeTime")(_vm.text.time))),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }