var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      staticClass: "mt-16",
      attrs: {
        transition: "scroll-x-reverse-transition",
        "min-width": "50",
        top: "",
        right: "",
        timeout: _vm.timeout,
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        _vm.snackbar = false
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.snackbar,
        callback: function ($$v) {
          _vm.snackbar = $$v
        },
        expression: "snackbar",
      },
    },
    [
      _c(
        "v-layout",
        { attrs: { "align-center": "", "pr-4": "" } },
        [
          _c("v-icon", { staticClass: "pr-3" }, [_vm._v(_vm._s(_vm.icon))]),
          _vm._v(" "),
          _c("v-layout", { attrs: { column: "" } }, [
            _c("div", [_vm._v(_vm._s(_vm.text))]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }