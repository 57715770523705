var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "7",
                    xs: "8",
                    md: "5",
                    lg: "4",
                    xl: "3",
                  },
                },
                [
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "a",
                        { attrs: { href: "/" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "text-center px-10 pt-5",
                              attrs: { justify: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-img", {
                                    staticClass: "mx-5 mb-0",
                                    attrs: { src: "../images/rcms_logo.svg" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-img", {
                                    staticClass: "px-1 mt-n5",
                                    attrs: { src: "../images/rcms_title.svg" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c("span", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n              For your security, kindly change your\n              "
                            ),
                            _c("strong", [_vm._v("Temporary Password")]),
                            _vm._v(".\n            "),
                          ]),
                          _vm._v(" "),
                          _vm.text
                            ? _c(
                                "v-alert",
                                {
                                  attrs: {
                                    dark: "",
                                    dense: "",
                                    type: "error",
                                    transition: "fade-transition",
                                  },
                                  model: {
                                    value: _vm.snackbar,
                                    callback: function ($$v) {
                                      _vm.snackbar = $$v
                                    },
                                    expression: "snackbar",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.text) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-form",
                            {
                              ref: "form",
                              staticClass: "mt-2",
                              attrs: { "lazy-validation": "" },
                              nativeOn: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.preventDefault()
                                  return _vm.newpassword.apply(null, arguments)
                                },
                              },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  required: "",
                                  "append-icon": _vm.showp
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showp ? "text" : "password",
                                  label: "Enter a new password",
                                  rules: _vm.passwordRules,
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showp = !_vm.showp
                                  },
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password",
                                },
                              }),
                              _vm._v(" "),
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  required: "",
                                  "append-icon": _vm.showp2
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showp2 ? "text" : "password",
                                  label: "Re-enter a new password",
                                  rules: _vm.confirmPasswordRules,
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showp2 = !_vm.showp2
                                  },
                                },
                                model: {
                                  value: _vm.form.newpassword,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "newpassword", $$v)
                                  },
                                  expression: "form.newpassword",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n              Password must be at least 8 characters long and should contain an uppercase letter, a lowercase letter,\n              a number, and a special character.\n            "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary mt-2",
                              attrs: {
                                large: "",
                                block: "",
                                loading: _vm.loading,
                              },
                              on: { click: _vm.newpassword },
                            },
                            [
                              _vm._v(
                                "\n              Change Password\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 d-flex justify-center",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary--text",
                                  attrs: { small: "", text: "" },
                                  on: { click: _vm.close },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-chevron-left"),
                                  ]),
                                  _vm._v(
                                    "Go back to login page\n              "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(
                        "Copyright © " +
                          _vm._s(_vm.moment().format("YYYY")) +
                          " Pylon. All rights reserved."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }