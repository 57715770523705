import Vue from "vue";
import VueRouter from "vue-router";
import Gate from "../gate";

Vue.use(VueRouter);
window.gate = new Gate(window.user);

const getDefaultProfileRoute = () => {
    if (gate.can("Update Information")) {
        return "/profile/update-info";
    } else if (gate.can("Generate New Key")) {
        return "/profile/generate-key";
    } else {
        return "/profile/change-password";
    }
};

const routes = [
    { path: "/", component: () => import("../components/dashboard/dashboard.vue"), name: "Dashboard", meta: { permissions: ["View Dashboard Analytics"] } },
    { path: "/gen-summ", component: () => import("../components/reports/GeneralSummary.vue"), name: "GeneralSummary", meta: { permissions: ["Nature of Tax", "Summary Collection", "Summary of Main Accounts", "GF-RCD/TRUST/SEF", "RCD"] } },
    { path: "/rpt", component: () => import("../components/reports/Rpt.vue"), name: "RPT", meta: { permissions: ["Real Property Tax Collection", "Advance Real Property Tax Collection", "Barangay Shares", "Advance Barangay Shares", "Report on RPT (Basic / SEF)"] } },
    { path: "/rpt-data-matching", component: () => import("../components/rpt/dataMatching.vue"), name: "DataMatching", meta: { permissions: ["RPT Data Sync"] } },
    { path: "/trans", component: () => import("../components/trans/trans.vue"), name: "Transactions", meta: { permissions: ["View Transaction List"] } },
    { path: "/tran-details/:id/:type/:or_type", component: () => import("../components/trans/details.vue"), name: "Transaction Details", meta: { permissions: ["View Transaction Details"] } },
    { path: "/cashier", component: () => import("../components/cashier/cashier.vue"), name: "Cashier", meta: { permissions: ["Create Transaction"] } },
    { path: "/sht-list", component: () => import("../components/cashier/sht/sht.vue"), name: "Sht", meta: { permissions: ["View SHT List"] } },
    { path: "/ctc-list", component: () => import("../components/cashier/ctc/ctc.vue"), name: "CTC", meta: { permissions: ["View CTC List"] } },
    { path: "/coll-accts", component: () => import("../components/collections/categorize.vue"), name: "Collection Accounts", meta: { permissions: ["View Collection Names"] } },
    { path: "/nature-of-tax", component: () => import("../components/collections/natureOfTax.vue"), name: "Nature of Tax", meta: { permissions: ["View Nature of Tax List"] } },
    { path: "/summ-of-coll", component: () => import("../components/collections/summColl.vue"), name: "Summary of Collections", meta: { permissions: ["View Summary of Collection List"] } },
    { path: "/main-accounts", component: () => import("../components/collections/Sma.vue"), name: "SMA", meta: { permissions: ["View Main Account List"] } },
    { path: "/gf-rcd-trust-sef", component: () => import("../components/collections/GFRCD.vue"), name: "GF-RCD/TRUST/SEF", meta: { permissions: ["View GF-RCD/TRUST/SEF List"] } },
    { path: "/employees", component: () => import("../components/employee/employee.vue"), name: "EmployeeList", meta: { permissions: ["View Employee List"] } },
    { path: "/cancelled-or", component: () => import("../components/cancelled-or/cancelled-or.vue"), name: "CancelledOR", meta: { permissions: ["View Cancelled OR List"] } },
    { path: "/or", component: () => import("../components/or/or.vue"), name: "OR", meta: { permissions: ["View OR List"] } },
    { path: "/brgy-list", component: () => import("../components/brgy/Barangays.vue"), name: "Barangays", meta: { permissions: ["View Barangay List"] } },
    { path: "/user-mgt", component: () => import("../components/users/users.vue"), name: "UserManagement", meta: { permissions: ["View User List"] } },
    { path: "/logs", component: () => import("../components/logs/logs.vue"), name: "Logs", meta: { permissions: ["View Log List"] } },
    { path: "/department-list", component: () => import("../components/depts/depts.vue"), name: "Departments", meta: { permissions: ["View Department List"] } },
    {
        path: "/profile",
        component: () => import("../components/profile/profile.vue"),
        name: "Profile",
        meta: { permissions: ["Update Information", "Generate New Key"] },
        redirect: getDefaultProfileRoute(),
        children: [
            { path: "update-info", component: () => import("../components/profile/update_info.vue"), meta: { permissions: ["Update Information"] } },
            { path: "change-password", component: () => import("../components/profile/change_password.vue") },
            { path: "generate-key", component: () => import("../components/profile/generate_new_key.vue"), meta: { permissions: ["Generate New Key"] } }
        ]
    },
    { path: "/roles-permissions", component: () => import("../components/rolesAndPermissions/rolesAndPermissions.vue"), name: "RolesAndPermissions", meta: { permissions: ["View Role List"] } },
    { path: "*", component: () => import("../components/dashboard/dashboard.vue"), meta: { permissions: ["Default Access"] } }
];

const router = new VueRouter({
    mode: "history",
    routes
});

const findFirstAllowedRoute = () => {
    for (let route of routes) {
        if (route.meta && route.meta.permissions) {
            if (route.meta.permissions.some(permission => gate.can(permission))) {
                return route.path;
            }
        }
    }
    return "/";
};

router.beforeEach((to, from, next) => {
    const requiredPermissions = to.meta.permissions || [];

    if (requiredPermissions.length > 0) {
        if (requiredPermissions.every(permission => gate.can(permission))) {
            next();
        } else {
            const fallbackRoute = findFirstAllowedRoute();
            next(fallbackRoute);
        }
    } else {
        next();
    }
});

export default router;