var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            "close-on-content-click": _vm.closeOnClick,
            width: "420px",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "mr-1",
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.openNotif()
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-badge",
                        {
                          attrs: {
                            dense: "",
                            content: _vm.unreadCount,
                            value: _vm.unreadCount,
                            color: "red",
                            overlap: "",
                          },
                        },
                        [
                          _c("v-icon", [
                            _vm._v(
                              "\n                        mdi-bell\n                    "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm.loading
            ? _c(
                "v-card",
                {
                  staticClass: "mx-auto",
                  staticStyle: { "max-height": "490px", "overflow-y": "auto" },
                  attrs: { width: "350" },
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "mt-5",
                      attrs: { elevation: "0" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "extension",
                            fn: function () {
                              return [
                                _c("v-skeleton-loader", {
                                  staticClass: "mr-3 mt-2",
                                  attrs: { type: "button", height: "30" },
                                }),
                                _vm._v(" "),
                                _c("v-skeleton-loader", {
                                  staticClass: "mr-3 mt-2",
                                  attrs: { type: "button", height: "30" },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3038087588
                      ),
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mt-5",
                            attrs: {
                              type: "heading",
                              height: "60",
                              width: "250",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c("v-skeleton-loader", {
                        attrs: { type: "avatar", height: "60" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-skeleton-loader", {
                    staticClass: "ml-3 mt-5",
                    attrs: { type: "text", height: "30", width: "70" },
                  }),
                  _vm._v(" "),
                  _vm._l(4, function (item) {
                    return _c(
                      "div",
                      [
                        _c(
                          "v-skeleton-loader",
                          _vm._b(
                            { attrs: { type: "list-item-avatar-three-line" } },
                            "v-skeleton-loader",
                            _vm.attrs,
                            false
                          )
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _c(
                "v-card",
                {
                  staticClass: "mx-auto",
                  staticStyle: { "max-height": "490px", "overflow-y": "auto" },
                  attrs: { width: "350" },
                  on: { scroll: _vm.handleScroll },
                },
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: { elevation: "0" },
                      scopedSlots: _vm._u([
                        {
                          key: "extension",
                          fn: function () {
                            return [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  class: {
                                    primary: _vm.activeButton === "all",
                                  },
                                  attrs: {
                                    small: "",
                                    rounded: _vm.activeButton === "all",
                                    text: _vm.activeButton !== "all",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setActiveButton("all")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        All\n                    "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mr-2",
                                  class: {
                                    primary: _vm.activeButton === "unread",
                                  },
                                  attrs: {
                                    small: "",
                                    rounded: _vm.activeButton === "unread",
                                    text: _vm.activeButton !== "unread",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setActiveButton("unread")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        Unread\n                    "
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("v-toolbar-title", [_vm._v("Notifications")]),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "mt-2",
                                          attrs: {
                                            color: "primary",
                                            fab: "",
                                            text: "",
                                            elevation: "0",
                                          },
                                          on: { click: _vm.markAllAsRead },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-icon", [
                                        _vm._v(" mdi-email-open-outline "),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [_vm._v(" "), _c("span", [_vm._v("Mark all as read")])]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.loadingFailed
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "my-10 d-flex justify-center align-center",
                        },
                        [
                          _c("Failed", {
                            attrs: { onFunctionComplete: _vm.fetchNotifs },
                          }),
                        ],
                        1
                      )
                    : _vm.formatted_notifs.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-list",
                            { attrs: { "two-line": "" } },
                            [
                              _vm._l(
                                _vm.formatted_notifs,
                                function (item, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      item.isHeader
                                        ? _c("v-subheader", {
                                            domProps: {
                                              textContent: _vm._s(item.header),
                                            },
                                          })
                                        : item.isDivider
                                        ? _c("v-divider", {
                                            attrs: { inset: item.inset },
                                          })
                                        : _c(
                                            "v-list-item",
                                            {
                                              class: {
                                                "grey lighten-2": !item.read,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.markAsRead(item)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-avatar",
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        size: "40",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "white--text text-capitalize",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name[0])
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("v-list-item-content", [
                                                _c("p", {
                                                  staticClass: "body-2",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.subtitle
                                                    ),
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "ma-0 pa-0 caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("relativeTime")(
                                                          item.created_at
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _vm._v(" "),
                              _vm._l(2, function (item) {
                                return _vm.adding
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-skeleton-loader",
                                          _vm._b(
                                            {
                                              attrs: {
                                                type: "list-item-avatar-three-line",
                                              },
                                            },
                                            "v-skeleton-loader",
                                            _vm.attrs,
                                            false
                                          )
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              }),
                              _vm._v(" "),
                              _vm.page >= _vm.last_page
                                ? _c("div", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "mt-5 text-center body-2",
                                      },
                                      [_vm._v("No more notifications")]
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _c("div", { staticClass: "pa-4" }, [
                        _c("p", { staticClass: "text-center body-2" }, [
                          _vm._v("You have no notifications"),
                        ]),
                      ]),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }