var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { attrs: { "fill-height": "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", align: "center" } },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "7",
                    xs: "8",
                    md: "5",
                    lg: "4",
                    xl: "3",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "text-center mb-5 px-10 pt-5",
                      attrs: { justify: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-img", {
                            staticClass: "mx-5 mb-0",
                            attrs: { src: "/images/rcms_logo.svg" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c("v-img", {
                            staticClass: "px-1 mt-n5",
                            attrs: { src: "/images/rcms_title.svg" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card",
                    { attrs: { outlined: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "primary", size: "150" } },
                            [_vm._v("mdi-check")]
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "mb-10 text-center text-h6" },
                            [_vm._v(" Payment Successful ")]
                          ),
                          _vm._v(" "),
                          _c("v-divider", { staticClass: "mb-3" }),
                          _vm._v(" "),
                          _c("p", { staticClass: "text-center" }, [
                            _vm._v(
                              " Reference Number: " + _vm._s(this.ref) + " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-card-text", { staticClass: "text-center" }, [
                    _c("span", [
                      _vm._v(
                        "Copyright © " +
                          _vm._s(_vm.moment().format("YYYY")) +
                          " Pylon. All rights reserved."
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }