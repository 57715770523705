<template>
  <v-app>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" xs="7" sm="6" md="5" lg="4" xl="5">
          <v-row justify="center" class="text-center">
            <v-col>
              <vue-qr
                v-show="false"
                ref="qr"
                qid="testid"
                :text="qr_code"
                logoSrc="../images/philippine-logo1.png"
                :margin="0"
              ></vue-qr>

              <div class="qr_content">
                <v-responsive
                  class="qr-id mx-5 mb-5"
                  :style="qrStyles"
                  :aspect-ratio="9 / 12"
                >
                </v-responsive>

                <span class="text">Amount to be paid:</span>
                <b class="amount">{{ amount | toCurrency }}</b>

                <v-responsive class="overlay-1">
                  {{ merchant_name }}
                </v-responsive>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import VueQr from "vue-qr";

export default {
  data: () => ({
    image: "",
    qr_code: "",
    amount: 0,
    merchant_name: "",
  }),

  computed: {
    qrStyles() {
      return {
        "--qr": "url(" + this.image + ")",
      };
    },
  },

  watch: {
    $route(to, from) {
      axios
        .post("/fetch-trans-cashier", { id: this.$route.query.awe })
        .then((res) => {
          this.qr_code = res.data.qrString;
          this.amount = parseFloat(res.data.amount);
          this.merchant_name = this.$route.query.merchant_name;
        })
        .catch((error) => {});
      
    },
  },

  methods: {},

  mounted() {
    this.$watch(
      () => {
        return this.$refs.qr.imgUrl;
      },
      (val) => {
        this.image = this.$refs.qr.imgUrl;
      }
    );
  },
};
</script>

<style scoped>
.qr-id {
  position: relative;
  height: 100%;
  width: 100%;
  background-position: top 50% left 50%;
  background-size: 50%, 100%;
  background-repeat: no-repeat;
  background-image: var(--qr), url("/images/QR-id.png");
}

.qr_content {
  display: grid;
  grid-auto-flow: row;
  place-items: center;
  color: #092e96;
}

/* Extra large */
@media screen and (min-width: 1904px) {
  .text {
    margin-top: -82%;
    font-size: 22px;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -72%;
    font-size: 29px;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -43%;
    font-size: 35px;
  }
}

/* Large */
@media screen and (min-width: 1264px) and (max-width: 1903px) {
  .text {
    margin-top: -86%;
    font-size: 13px;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -76%;
    font-size: 14px;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -48%;
    font-size: 19px;
  }
}

/* Medium */
@media screen and (min-width: 960px) and (max-width: 1263px) {
  .text {
    margin-top: -86%;
    font-size: 13px;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -76%;
    font-size: 14px;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -47%;
    font-size: 19px;
  }
}

/* Small */
@media screen and (min-width: 801px) and (max-width: 959px) {
  .text {
    margin-top: -85%;
    font-size: 13px;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -75%;
    font-size: 14px;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -47%;
    font-size: 20px;
  }
}

@media screen and (min-width: 701px) and (max-width: 800px) {
  .text {
    margin-top: -86%;
    font-size: 12px;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -76%;
    font-size: 14px;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -48%;
    font-size: 19px;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .text {
    margin-top: -88%;
    font-size: 12px;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -78%;
    font-size: 14px;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -50%;
    font-size: 16px;
  }
}

/* Extra small */
@media screen and (min-width: 450px) and (max-width: 599px) {
  .text {
    margin-top: -84%;
    font-size: 3vw;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -74%;
    font-size: 4vw;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -46%;
    font-size: 25px;
  }
}

@media screen and (min-width: 400px) and (max-width: 449px) {
  .text {
    margin-top: -85%;
    font-size: 3vw;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -75%;
    font-size: 4vw;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -47%;
    font-size: 22px;
  }
}

@media screen and (min-width: 350px) and (max-width: 399px) {
  .text {
    margin-top: -87%;
    font-size: 3vw;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -77%;
    font-size: 4vw;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -49%;
    font-size: 19px;
  }
}

@media screen and (min-width: 300px) and (max-width: 349px) {
  .text {
    margin-top: -89%;
    font-size: 3vw;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -79%;
    font-size: 4vw;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -51%;
    font-size: 16px;
  }
}

@media screen and (max-width: 299px) {
  .text {
    margin-top: -93%;
    font-size: 3vw;
    text-align: center;
    z-index: 1;
  }
  .amount {
    margin-top: -83%;
    font-size: 4vw;
    text-align: center;
    z-index: 1;
  }
  .overlay-1 {
    margin-top: -54%;
    font-size: 5vw;
  }
}
</style>
