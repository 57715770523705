<template>
  <v-app>
    <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="7" xs="8" md="5" lg="4" xl="3">
          <v-card outlined>
            <a href="/">
              <v-row justify="center" class="text-center px-10 pt-5">
                <v-col>
                  <v-img class="mx-5 mb-0" src="../images/rcms_logo.svg"></v-img>
                </v-col>
                <v-col>
                  <v-img class="px-1 mt-n5" src="../images/rcms_title.svg"></v-img>
                </v-col>
              </v-row>
            </a>
            <v-card-text class="text-center">
              <span class="text-center">
                We will send a <strong>Reset Password Link</strong> in this email address.
              </span>
              <v-alert v-model="snackbar" dark dense type="error" transition="fade-transition">
                {{ text }}
              </v-alert>
              <v-form class="mt-2" ref="form" v-model="valid" lazy-validation :disabled="disable"
                @keydown.native.enter.prevent="send">
                <v-text-field outlined required label="Email Address" :rules="rules.email" type="email"
                  v-model="form.email" />
                <v-btn class="mb-2 primary" :small="$vuetify.breakpoint.smAndDown" :large="$vuetify.breakpoint.mdAndUp"
                  block :loading="loading" :disabled="disable" @click="send">
                  <span>Send Password Reset Link</span>
                </v-btn>
              </v-form>
              <i class="text-center">
                Click RESEND if you haven't received the email within ({{ seconds }})
                seconds.
              </i>
              <v-spacer></v-spacer>
              <v-btn class="mt-2 primary--text" small text :loading="resendLoading" @click="resend">
                RESEND LINK
              </v-btn>
            </v-card-text>
          </v-card>
          <v-card-text class="text-center">
            <span>Copyright © {{ moment().format("YYYY") }} Pylon. All rights reserved.</span>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    seconds: 60,
    loading: false,
    resendLoading: false,
    disable: false,
    disableResend: false,
    valid: true,
    snackbar: false,
    text: true,
    inteval: "",
    form: {
      email: "",
    },
    rules: {
      email: [
        (v) => !!v || "Email address is required",
        (v) => !v || /.+@.+\..+/.test(v) || "Your email address is incorrect",
      ],
    },
  }),

  methods: {
    send() {
      this.loading = true;
      if (this.rules.email[2]) {
        this.rules.email.splice(2)
      }
      if (this.$refs.form.validate()) {
        axios
          .post("/password/email", this.form)
          .then((res) => {
            this.loading = false;
            this.disable = true;
            this.inteval = setInterval(() => {
              if (this.seconds > 0) {
                this.seconds--;
              } else {
                clearInterval(this.inteval);
                this.seconds = 60;
                this.disableResend = true;
              }
            }, 1000);
          })
          .catch((error) => {
            this.loading = false;
            if (
              error.response.data.errors.email ==
              "Please wait in 60 seconds before retrying."
            ) {
              this.disable = true;
              axios.get("/timer/" + this.form.email).then((res) => {
                var fromDate = new Date().getTime();
                var toDate = moment(res.data.created_at);

                var diff = toDate.diff(fromDate, "seconds");
                this.seconds = diff + 60;
                this.timer();
              });
            } else {
              if (error.response.data.errors.email !== undefined) {
                this.rules.email.push((v) => false || error.response.data.errors.email[0])
              }
              this.$refs.form.validate()
            }
          });
      } else {
        this.loading = false;
      }
    },

    resend() {
      if (this.disableResend) {
        this.resendLoading = true;
        if (this.$refs.form.validate()) {
          axios
            .post("/password/email", this.form)
            .then((res) => {
              this.timer();
              this.resendLoading = false;
            })
            .catch((error) => {
              this.text = "Error! Please re-try.";
              if (error.response.data.errors.email !== undefined) {
                this.text = error.response.data.errors.email[0];
              }
              this.snackbar = true;
              clearTimeout(this.inteval);
              this.inteval = setTimeout(() => {
                this.snackbar = false;
              }, 5000);
              this.resendLoading = false;
            });
          this.disableResend = false;
        }
      }
    },

    timer() {
      this.inteval = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--;
        } else {
          clearInterval(this.inteval);
          this.seconds = 60;
          this.disableResend = true;
        }
      }, 1000);
    },
  },

  mounted() { },
};
</script>
